import React, { useState, useEffect, useRef } from 'react'
import { Flex } from 'rebass/styled-components';
import { navigate } from '@reach/router';

// COMPONENTS
import Header from './../../organisms/header';
import Menu from './../../organisms/menu';
import Footer from './../../organisms/footer';
import ScrollToTop from './../../atoms/scrollToTop';
import CookiesInfo from './../../molecules/cookiesInfo';
import Notification from './../../molecules/notification';

// STYLES
import { main_styles } from './styles';

export default function Layout(props) {

    const [activeMenu, setActiveMenu] = useState(false);
    const [hasScrolled, setHasScrolled] = useState(false);
    const [cookiesInfoStatus, setCookiesInfoStatus] = useState(false);

    const openMenu = () => setActiveMenu(true);
    const closeMenu = () => setActiveMenu(false);

    let cookies = useRef();

    const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100 && !hasScrolled) {
              setHasScrolled(true)
            } else if (window.scrollY < 100 && hasScrolled) {
              setHasScrolled(false)
            }
        })
    }, [hasScrolled])

    useEffect(() => {
        cookies.current = localStorage.getItem('ArdorCookies');

        if (cookies.current === null) {
            setCookiesInfoStatus(true);
        } else {
            setCookiesInfoStatus(false);
        }

    }, [setCookiesInfoStatus])

    return (
        <>
            <Header activeMenu={activeMenu} openMenu={openMenu} closeMenu={closeMenu} />
            <Flex as='main' sx={main_styles(104)}>
                <Menu
                activeMenu={activeMenu}
                openMenu={openMenu}
                closeMenu={closeMenu} />
                {props.children}
            </Flex>
            <Footer path={props.path} />
            <ScrollToTop hasScrolled={hasScrolled} scrollTop={scrollTop} />
            <CookiesInfo status={cookiesInfoStatus}
            onAccept={() => {
                localStorage.setItem('ArdorCookies', true)
                setCookiesInfoStatus(false)
            }}
            onReadMore={() => {
                localStorage.setItem('ArdorCookies', true)
                navigate('/prywatnosc')
                setCookiesInfoStatus(false)
            }} />
            {/* <Notification /> */}
        </>
    )
}