import React from 'react'
import { Flex } from 'rebass/styled-components';

// STYLES
import {
    footer_info_props,
    info_container_props,
    heading_info_props,
    text_info_props
} from './styles';

// COMPONENTS
import Text from './../../atoms/text';
import Heading from './../../atoms/heading';

export default function FooterInfo() {

    return (
        <Flex {...footer_info_props}>
            <Flex sx={info_container_props}>
                <Heading {...heading_info_props}>Nasi Doradcy</Heading>
                <Text {...text_info_props}>Krzysztof - 502 673 505</Text>
                <Text {...text_info_props}>Kaja - 667 929 189</Text>
            </Flex>
            <Flex sx={info_container_props}>
                <Heading {...heading_info_props}>Nasz salon</Heading>
                <Text {...text_info_props}>ul. Fordońska 420 85-790 Bydgoszcz</Text>
                <Text {...text_info_props}>Godziny otwarcia - Pn-Pt 10-16</Text>
            </Flex>
        </Flex>
    )
}
